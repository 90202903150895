<ul class="p-0 m-0 flex flex-row justify-center list-none">
  <li class="mx-2">
    <a class="text-white opacity-50 hover:opacity-100 text-xs font-mono" href="https://github.com/bentzibentz" target="_blank" rel="noopener nofollow" title="Besuch mich auf Github">
      Github
    </a>
  </li>
  <li class="mx-2">
    <a class="text-white opacity-50 hover:opacity-100 text-xs font-mono" href="https://www.instagram.com/sachen_fuer_helden" target="_blank" rel="noopener nofollow" title="Folge mir auf Instagram">
      Instagram
    </a>
  </li>
  <li class="mx-2">
    <a class="text-white opacity-50 hover:opacity-100 text-xs font-mono" href="https://www.xing.com/profile/Fabian_Bentz2" target="_blank" rel="noopener nofollow" title="Besuch mein Xing Profil">
      Xing
    </a>
  </li>
</ul>
