import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import isSameDay from 'date-fns/isSameDay';

@Injectable({
  providedIn: 'root'
})
export class TrekkerService {

  private url = environment.trekker;

  constructor() { }

  tracking() {
    let allowTrack = false;
    const currentDate = new Date();
    const saved = localStorage.getItem('trekker');

    if (saved) {
      const savedDate = new Date(saved);
      const sameDay = isSameDay(savedDate, currentDate);

      if (!sameDay) {
        allowTrack = true;
      }
    } else {
      localStorage.setItem('trekker', currentDate.toString());
      allowTrack = true;
    }

    if (!allowTrack) {
      return;
    }

    const track = {
      ui_id: uuidv4()
    }
    console.log('start fetch');
    fetch(this.url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(track)
    })
      .then( () => {})
      .catch((err) => {
        console.log(err);
      });
  }
}
