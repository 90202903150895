import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { WINDOW } from '../../services/window.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private title: Title,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Fabian Bentz - Senior Frontend-Developer aus Mannheim, Deutschland.');
    // console.log(window);
    // console.log(window.document.getElementById('intro'));
  }

}
