import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../../../interfaces/item';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  @Input() idx: number;
  @Input() project: Item;

  public reverse = '';

  constructor(
  ) { }

  ngOnInit(): void {

    if (this.idx % 2 !== 0 ) {
      this.reverse = 'item--reverse';
    }

  }

}
