import { Component, OnInit } from '@angular/core';
import { Ref } from '../../../interfaces/ref';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {

  public references: Array<Ref>;

  constructor() { }

  ngOnInit(): void {

    this.references = [
      {
        id: 1,
        title: 'Zone 7 GmbH & Co. KG',
        image: 'assets/references/ref-zone.png',
        link: 'https://www.zonesieben.de/'
      },
      {
        id: 2,
        title: 'RON TV',
        image: 'assets/references/ref-ron.png',
        link: 'https://www.rontv.de/'
      },
      {
        id: 3,
        title: 'Weingut Hess',
        image: 'assets/references/ref-hess-weingut.png',
        link: 'https://hess-weingut.de/'
      },
      {
        id: 4,
        title: 'Hensel Projekt- und Grundstücksentwicklungsgesellschaft mbH',
        image: 'assets/references/ref-hensel.png',
        link: 'https://hensel-immobilien.de/'
      },
      {
        id: 5,
        title: 'Anwaltskanzlei BFS Rechtsanwälte Berberich, Friedrich, Schmucker & Coll',
        image: 'assets/references/ref-bfs.png',
        link: 'https://www.bfs-nw.de/'
      },
      {
        id: 6,
        title: 'Bag Orderdays Munich',
        image: 'assets/references/ref-bag-order-days.png',
        link: 'https://www.bagorderdaysmunich.de/'
      }
    ];

  }

}
