import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit(): void {}

  goTo(path: string) {
    const element = document.querySelector(`#${path}`);

    console.log(element);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.router.navigateByUrl('').then(
        () => {
          const id = document.querySelector(`#${path}`);
          id.scrollIntoView({ behavior: 'smooth' });
        }
      );
    }
  }

}
