<section class="w-full pt-8 sm:pt-16 pb-8 bg-yellow-400 flex justify-center" id="intro">
  <div class="container text-center pt-8 sm:pt-16 pb-8">
    <h1 class="text-black text-4xl sm:text-5xl md:text-7xl leading-tight font-headline sm:mt-16 mb-10">It's pretty simple,<br>I sit down and i code</h1>

    <div class="w-full sm:max-w-75 md:max-w-50 mx-auto">
      <p class="text-base sm:text-lg">
        Hallo, schön dass Sie den Weg auf meine Website gefunden haben. Ich beschäftige mich seit meinen ersten Schritten am Computer mit dem Design und dem Programmieren von Websites.
        Über die Zeit wurde aus diesem Interesse eine Leidenschaft, heute arbeite ich mit viel Engagement als Senior Frontend- und Software Developer.
      </p>
      <p>
        <button mat-icon-button class="text-2xl" aria-label="Zu den Projekten" (click)="scrollTo()">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </p>
    </div>
  </div>
</section>
