import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollTo() {
    const id = document.querySelector(`#showcase`);
    id.scrollIntoView({ behavior: 'smooth' });
  }

}
