<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content>

    <mat-toolbar class="bg-yellow-400 flex flex-row justify-between">
      <a routerLink="/" class="logo logo--small" title="Startseite aufrufen">
        <svg xmlns="http://www.w3.org/2000/svg" width="53.083" height="39.155"><path data-name="Pfad 1" d="M25.779 7.771c0-3.408-3.7-6.1-8.448-6.1a6.7 6.7 0 00-4.32 1.248 34.733 34.733 0 00-5.472 5.9l-5.472 6.912h2.832v15.656c0 2.976-.144 3.936-.72 4.608-.576.72-1.3.96-3.12 1.056v.48h16.7v-.48c-2.256-.048-3.12-.288-3.7-1.056-.576-.72-.72-1.632-.72-4.608V15.739h4.032l1.248-2.256h-5.272V7.099c0-2.928 1.008-4.752 2.688-4.752a1.72 1.72 0 011.584 1.3 8.5 8.5 0 01.24 2.16c.144 2.688.336 3.456 1.2 4.32a4.128 4.128 0 002.976 1.244 3.572 3.572 0 003.744-3.6zm26.3 16.416c0-6.576-3.936-11.28-9.36-11.28a10.456 10.456 0 00-7.008 2.832V1.387c-2.448.816-3.216 1.056-6.24 1.872-2.976.768-3.84.96-6.384 1.488l.048.528c1.632.048 2.5.432 3.168 1.392.72 1.008.96 2.112.96 4.992v26.496h.484c.384-1.344 1.008-1.968 2.16-1.968a4.561 4.561 0 011.392.336l1.2.384c.672.24 1.1.384 1.152.384a17.17 17.17 0 002.596.672 14.954 14.954 0 002.5.192c7.624 0 13.336-6 13.336-13.968zm-8.972 1.824c0 7.728-1.44 11.52-4.46 11.52-2.116 0-2.932-1.44-2.932-5.184v-15.84a3.754 3.754 0 012.88-1.344c3.168 0 4.512 3.312 4.512 10.848z" stroke="#000" stroke-width="2"/></svg>
      </a>

      <div *ngIf="!(isHandset$ | async)">
        <a mat-button class="text-black font-mono" routerLink="/">Start</a>
        <a mat-button class="text-black font-mono" routerLink="/datenschutz">Datenschutz</a>
        <a mat-button class="text-black font-mono" routerLink="/impressum">Impressum</a>
      </div>

      <div *ngIf="(isHandset$ | async)">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="text-black" aria-label="Mobile Navigation öffnen und schließen">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <span mat-menu-item class="text-white font-mono" routerLink="/">Start</span>
          <span mat-menu-item class="text-white font-mono" routerLink="/datenschutz">Datenschutz</span>
          <span mat-menu-item class="text-white font-mono" routerLink="/impressum">Impressum</span>
        </mat-menu>
      </div>

    </mat-toolbar>

    <ng-content></ng-content>

  </mat-sidenav-content>
</mat-sidenav-container>
