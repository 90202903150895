<article class="w-full flex flex-row flex-wrap items-center my-16" [id]="project.id">

  <div class="item" [ngClass]="reverse">

    <div class="item__image">
      <picture [ngStyle]="{'background-color': project.color_key}">
        <source srcset="{{project.image_webp}}" type="image/webp">
        <source srcset="{{project.image_web}}" type="image/jpeg">
        <img src="{{project.image_web}}" alt="Alt Text!">
      </picture>
      <div class="item__overlay" [ngStyle]="{'background-color': project.color_key}"></div>
    </div>

    <!--
    <div class="item__image" [ngStyle]="{'background-color': project.color_key, 'background-image': 'url(' + project.image_web + ')'}"></div>
    -->

    <!--
    <picture>
      <source [srcset]="project.image_webp" type="image/webp">
      <source [srcset]="project.image_web" type="image/jpeg">
      <img
        class="rounded-lg mb-4 md:mb-0 w-full h-auto"
        [src]="project.image_web"
        [alt]="project.title"
      >
    </picture>
    -->

    <div class="item__content">
      <div class="item__content__wrapper sm:shadow-lg" [ngStyle]="{'background-color': project.color_card}">
        <p class="text-white text-xs font-mono font-normal mb-8 opacity-75">{{project.technology}}</p>
        <h2 class="text-white text-2xl md:text-2xl text-center font-headline mb-8 mt-4 leading-tight">{{project.title}}</h2>
        <p class="text-white text-xs font-mono mb-4 opacity-75" [innerHTML]="project.text"></p>
        <p class="text-right">
          <a class="text-white font-semibold opacity-75 hover:opacity-100" [href]="project.link" target="_blank" rel="noopener" title="Projekt Website aufrufen">
            <mat-icon>arrow_right_alt</mat-icon>
          </a>
        </p>
      </div>
    </div>

  </div>

</article>
