import { Component, OnInit } from '@angular/core';
import { Item } from '../../../interfaces/item';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  public projects: Array<Item>;

  constructor() { }

  ngOnInit(): void {

    this.projects = [
      {
        id: 11,
        title: 'infoday.io',
        text: 'Virtuelle SAP-Infotage. infoday.io ist eine Online-Platform für virtuelle Konferenzen rund um SAP Tools und Software. Konzeption und Frontend-Entwicklung.',
        link: 'https://infoday.io',
        client: 'Espresso Tutorials GmbH',
        technology: 'Angular',
        image_web: 'assets/projects/infoday-mockup.jpg',
        image_webp: 'assets/projects/infoday-mockup.webp',
        image_logo: 'assets/projects/infoday-mockup.webp',
        color_key: '#f39100',
        color_card: '#33a8e0'
      },
      {
        id: 12,
        title: 'KST Motorenversuch',
        text: 'Programmierung einer mehrsprachrigen Website auf Basis von Wordpress für die KST-Motorenversuch GmbH & Co. KG. Design von Mirko Mayerhöffer. Fotografie von <a class="underline hover:no-underline" href="https://fabianhensel.de" target="_blank" rel="noopener">Fabian Hensel</a>.',
        link: 'https://kst-motorenversuch.de',
        client: 'KST-Motorenversuch GmbH & Co. KG',
        technology: 'Wordpress',
        image_web: 'assets/projects/kst-mockup.jpg',
        image_webp: 'assets/projects/kst-mockup.webp',
        image_logo: 'assets/projects/kst-mockup.webp',
        color_key: '#243271',
        color_card: '#F19001'
      },
      {
        id: 1,
        title: 'et.training',
        text: 'Learn SAP any time and any where. et.training ist eine Online-Lernplatform für SAP und bietet sowohl E-Books als auch Video-Tutorials. Programmierung und Design.',
        link: 'https://et.training',
        client: 'Espresso Tutorials GmbH',
        technology: 'Angular / Ionic',
        image_web: 'assets/projects/et-mockup.jpg',
        image_webp: 'assets/projects/et-mockup.webp',
        image_logo: 'assets/projects/et-mockup.webp',
        color_key: '#f39100',
        color_card: '#33a8e0'
      },
      {
        id: 4,
        title: 'Wurst App Your Life',
        text: 'Wurst machen leicht gemacht! Wurst App Your Life ist die iOS und Android App rund um das Thema selbstgemachte Wurst. Mit einfachen Schritt für Schritt Anleitungen, Kochrezepten, Metzgerei-Register und vielem mehr. Design und Programmierung.',
        link: 'https://www.wurst-app.de',
        client: 'Fabian Bentz & Fabian Hensel Gbr',
        technology: 'Angular / Ionic',
        image_web: 'assets/projects/wurst-mockup.jpg',
        image_webp: 'assets/projects/wurst-mockup.webp',
        image_logo: 'assets/projects/wayl-mockup.webp',
        color_key: '#d17267',
        color_card: '#96281b'
      },
      {
        id: 10,
        title: 'Espresso Tutorials',
        text: 'Programmierung eines mehrsprachrigen Online Shops mit Anbindungen an verschiedene Zahlungsanbieter und Warenwirtschaftssystem auf Basis von Worpdress und Woocommerce.',
        link: 'https://www.espresso-tutorials.de/',
        client: 'Espresso Tutorials',
        technology: 'Wordpress / Woocommerce',
        image_web: 'assets/projects/et-com-mockup.jpg',
        image_webp: 'assets/projects/et-com-mockup.webp',
        image_logo: 'assets/projects/et-com-mockup.webp',
        color_key: '#f39100',
        color_card: '#33a8e0'
      },
      {
        id: 2,
        title: 'Boulderhallen finden',
        text: 'Finde die besten und neuesten Boulderhallen in ganz Deutschland auf einen Blick. Erhalte aktuelle Informationen zu den jeweiligen Angeboten, Öffnungszeiten, Preisen, u.v.m. Programmierung der PWA und App.',
        link: 'https://boulderhallen-finden.de',
        client: 'Triple F',
        technology: 'Angular / Ionic',
        image_web: 'assets/projects/boulderhallen-mockup.jpg',
        image_webp: 'assets/projects/boulderhallen-mockup.webp',
        image_logo: 'assets/projects/boulder-mockup.webp',
        color_key: '#21d1c2',
        color_card: '#00344f'
      },
      {
        id: 3,
        title: 'Library 2 App',
        text: 'Learn SAP any time and any where. et.training ist eine Lern-App (iOS und Android) für SAP und bietet sowohl E-Books als auch Video-Tutorials. Design und Programmierung.',
        link: 'https://et.training',
        client: 'Espresso Tutorials GmbH',
        technology: 'Angular / Ionic',
        image_web: 'assets/projects/library-mockup.jpg',
        image_webp: 'assets/projects/library-mockup.webp',
        image_logo: 'assets/projects/library-mockup.webp',
        color_key: '#f39100',
        color_card: '#33a8e0'
      },
      {
        id: 8,
        title: 'Henselwein',
        text: 'Familiäre Tradition, seit über 300 Jahren – Programmierung der Website für das Weingut Hensel - Bad Dürkheim an der Weinstraße. Design von Mirko Mayerhöffer. Fotografie von <a class="underline hover:no-underline" href="https://fabianhensel.de" target="_blank" rel="noopener">Fabian Hensel</a>',
        link: 'https://henselwein.de/',
        client: 'Weingut Hensel',
        technology: 'Wordpress',
        image_web: 'assets/projects/henselwein-mockup.jpg',
        image_webp: 'assets/projects/henselwein-mockup.webp',
        image_logo: 'assets/projects/henselwein-mockup.webp',
        color_key: '#ffffff',
        color_card: '#b4a871'
      },
      {
        id: 5,
        title: 'Weingut Odinstal',
        text: 'Das Weingut Odinstal liegt hoch über Wachenheim in der Pfalz, umgeben von 5 Hektar gutseigenen Weinbergen in der Monopollage Odinstal. Programmierung der Wesbite. Fotografie von <a class="underline hover:no-underline" href="https://fabianhensel.de" target="_blank" rel="noopener">Fabian Hensel</a>.',
        link: 'https://odinstal.de',
        client: 'Weingut Odinstal',
        technology: 'Wordpress',
        image_web: 'assets/projects/odinstal-mockup.jpg',
        image_webp: 'assets/projects/odinstal-mockup.webp',
        image_logo: 'assets/projects/odinstal-mockup.webp',
        color_key: '#d10000',
        color_card: '#000000'
      },
      {
        id: 6,
        title: 'Fabian Hensel Photography',
        text: '„ALL I EVER WANT TO BE IS WHO I AM – A PHOTOGRAPHER“ – Programmierung der Portfolio Website für Fabian Hensel Photography aus Mannheim, Deutschland.',
        link: 'https://www.fabianhensel.de/',
        client: 'Fabian Hensel Photography',
        technology: 'Wordpress',
        image_web: 'assets/projects/hensel-mockup.jpg',
        image_webp: 'assets/projects/hensel-mockup.webp',
        image_logo: 'assets/projects/odinstal-mockup.webp',
        color_key: '#ffffff',
        color_card: '#2b2b2b'
      },
      {
        id: 7,
        title: 'Hueblog',
        text: 'Faszination rund um das smarte Licht – Programmierung der Blog Website für den inoffiziellen Hueblog.de, sowie die Hueblog App für iOS und Android.',
        link: 'https://hueblog.de/',
        client: 'appgefahren GmbH',
        technology: 'Wordpress',
        image_web: 'assets/projects/hueblog-mockup.jpg',
        image_webp: 'assets/projects/hueblog-mockup.webp',
        image_logo: 'assets/projects/odinstal-mockup.webp',
        color_key: '#fe487b',
        color_card: '#00bb95'
      },
      {
        id: 9,
        title: 'Meier Lederwaren',
        text: 'Als moderner Familienbetrieb mit Sitz in der Lederstadt Offenbach am Main, designt und produziert Meier Lederwaren Handtaschen und Accessoires seit 1921.',
        link: 'https://meier-lederwaren.de/',
        client: 'Meier Lederwaren',
        technology: 'Wordpress',
        image_web: 'assets/projects/meier-mockup.jpg',
        image_webp: 'assets/projects/meier-mockup.webp',
        image_logo: 'assets/projects/meier-mockup.webp',
        color_key: '#ffffff',
        color_card: '#2b2b2b'
      },
      {
        id: 13,
        title: 'Kontolino!',
        text: 'Programmierung der neuen Kontolino! Website auf Basis von Wordpress. Design von Mirko Mayerhöffer.',
        link: 'https://kontolino.de',
        client: 'Kontolino! Online-Buchhaltungssoftware.',
        technology: 'Wordpress',
        image_web: 'assets/projects/kontolino-mockup.jpg',
        image_webp: 'assets/projects/kontolino-mockup.webp',
        image_logo: 'assets/projects/kontolino-mockup.webp',
        color_key: '#fff9f0',
        color_card: '#faa629'
      },
      {
        id: 14,
        title: 'Pflegedienst Mayerhöffer',
        text: 'Programmierung der neuen Pflegedienst Mayerhöffer Website auf Basis von Wordpress. Design von Mirko Mayerhöffer.',
        link: 'https://pflegedienst-sinsheim.de/',
        client: 'Pflegedienst Mayerhöffer',
        technology: 'Wordpress',
        image_web: 'assets/projects/pflegedienst-mockup.jpg',
        image_webp: 'assets/projects/pflegedienst-mockup.webp',
        image_logo: 'assets/projects/pflegedienst-mockup.webp',
        color_key: '#FF2359',
        color_card: '#01215D'
      },
    ];

  }

}
