<section class="bg-gray-900 w-full flex justify-center items-center pt-16 pb-8" id="kontakt">
  <div class="container">
    <div class="text-center py-16">
      <h1 class="text-white text-3xl sm:text-4xl md:text-5xl font-headline leading-tight mb-16 mt-16">You have questions or a cool idea,<br>just write me an email</h1>

      <p class="mb-16">
        <a class="appearance-none bg-white text-black opacity-100 hover:opacity-75 py-2 px-6 rounded-full" href="mailto:info@fabianbentz.de" title="Fragen, Angebote, Ideen… schreib mir eine E-Mail">info@fabianbentz.de</a>
      </p>

      <app-social></app-social>
    </div>
  </div>
</section>
<section class="bg-gray-900 py-2">
  <div class="container">
    <p class="text-white text-center text-xs opacity-25 font-mono">Copyright © {{year}} Fabian Bentz</p>
  </div>
</section>
