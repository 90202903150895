<section class="h-auto w-full bg-gray-800 py-8" id="references">

  <div class="container">

    <div class="w-full sm:px-12">

      <h2 class="sr-only">Auszug weiterer Kunden</h2>
      <div class="flex flex-row flex-wrap justify-center sm:px-8">
        <ng-container *ngFor="let reference of references; let i = index">
          <div class="w-1/2 sm:w-1/6" [id]="reference.id">
            <a [href]="reference.link" target="_blank" rel="nofollow noopener" [title]="reference.title">
              <img class="opacity-50" [src]="reference.image" [alt]="reference.title" fetchpriority="low" decoding="async" loading="lazy">
            </a>
          </div>
        </ng-container>
      </div>

    </div>

  </div>

</section>
