<section class="min-h-full w-full bg-yellow-400 pt-8 pb-4" id="showcase">

  <div class="container py-8">

    <div class="w-full sm:px-12">
      <ng-container *ngFor="let project of projects; let i = index">
        <app-item [project]="project" [idx]="i"></app-item>
      </ng-container>
    </div>

  </div>

</section>
